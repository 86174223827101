<table class="table">
  <thead>
    <tr>
      <th class="text-center">Variable Rate Code</th>
      <th class="text-center">Variable Rate Description</th>
      <th class="text-center">Tracker
        <i [ngbTooltip]="trackerInfo" class="fas fa-info-circle tracker-info"></i>
      </th>
      <th class="text-center rate-col">Current Rate</th>
      <th *ngIf="hasScheduled" class="text-center rate-col">Scheduled Rate {{ scheduledDateFormatted }}</th>
      <th class="text-center rate-col">New Rate</th>
      <th *ngIf="isProductAdminView" class="text-center">Delete</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngIf="variableRateCodes.length === 0">
      <td class="text-center" colspan="999">
        <i class="fas fa-info-circle"></i>
        No codes have been configured yet. Upload products to configure new codes.
      </td>
    </tr>

    <tr *ngFor="let variableRateCode of variableRateCodes; let index = index; trackBy: trackByCode" [formGroup]="form.at(index)">
      <td *ngIf="variableRateCode.code" class="align-middle" [ngClass]="{'strikethrough': variableRateCode.markedAsDeleted}">
        <div class="d-flex justify-content-center">
          {{ variableRateCode.code }}
        </div>
      </td>
      <td *ngIf="!variableRateCode.code" class="align-middle">
        <input
          type="text"
          class="form-control form-control-sm"
          [class.is-invalid]="invalid(index, 'code')"
          [class.is-valid]="valid(index, 'code')"
          maxlength="255"
          formControlName="code"
        >
        <div class="invalid-feedback d-block" *ngIf="invalid(index, 'code')">
          <span *ngIf="errors(index, 'code').required">Please enter a value</span>
          <span *ngIf="errors(index, 'code').duplicateValue">Variable rate code already exists</span>
        </div>
      </td>
      <td class="align-middle">
        <input
          type="text"
          class="form-control form-control-sm"
          [class.is-invalid]="invalid(index, 'description')"
          [class.is-valid]="valid(index, 'description')"
          maxlength="255"
          formControlName="description"
        >
        <div class="invalid-feedback d-block" *ngIf="invalid(index, 'description')">
          <span *ngIf="errors(index, 'description').required">Please enter a value</span>
          <span *ngIf="errors(index, 'description').maxLength">Please a maximum of 255 characters</span>
        </div>
      </td>
      <td class="align-middle">
        <div class="d-flex justify-content-center">
          <input
            type="checkbox"
            class="form-check-input"
            formControlName="isTracker"
            (change)="trackerChanged(index)"
          >
        </div>
      </td>
      <td class="align-middle" [class.is-new]="variableRateCode.isNew">
        <div class="input-group input-group-sm" *ngIf="!variableRateCode.isNew">
          <input type="number" class="form-control rounded-0" value="{{ variableRateCode.currentRate }}" disabled>
          <span class="input-group-text rounded-0">%</span>
        </div>
      </td>
      <td *ngIf="hasScheduled" [class.is-new]="variableRateCode.isNew">
        <div class="input-group input-group-sm" *ngIf="!variableRateCode.isNew">
          <input type="number" class="form-control rounded-0" value="{{ variableRateCode.scheduledRate }}" disabled>
          <span class="input-group-text rounded-0">%</span>
        </div>
      </td>
      <td class="align-middle">
        <div class="input-group input-group-sm" [class.is-invalid]="invalid(index, 'variableRate')" [class.is-valid]="valid(index, 'variableRate')">
          <input type="number" class="form-control rounded-0" formControlName="variableRate" min="0" max="100" step="0.1">
          <span class="input-group-text rounded-0">%</span>
        </div>
        <div class="invalid-feedback d-block" *ngIf="invalid(index, 'variableRate')">
          <span *ngIf="errors(index, 'variableRate').required">Please enter a value</span>
          <span *ngIf="errors(index, 'variableRate').greaterThan !== undefined">Please enter a value above {{ errors(index, 'variableRate').greaterThan }}</span>
          <span *ngIf="errors(index, 'variableRate').lessThan">Please enter a value below {{ errors(index, 'variableRate').lessThan }}</span>
          <span *ngIf="errors(index, 'variableRate').negativeRate">This will result in some of your products having a negative initial_rate or rate2. Please check your products or change this rate accordingly</span>
        </div>
      </td>
      <td *ngIf="isProductAdminView" class="text-center align-middle">
        <button *ngIf="!variableRateCode.markedAsDeleted" type="button" class="btn btn-danger btn-sm" (click)="deleteVariableRateCodeClick(index)">Delete</button>
        <div *ngIf="variableRateCode.markedAsDeleted" class="d-flex justify-content-center text-danger">Deleted</div>
      </td>
    </tr>
  </tbody>
</table>
<div *ngIf="canAddNew" class="text-end px-2 mb-3">
  <button type="button" class="btn btn-2022--secondary btn-sm" (click)="addVariableRateCodeClick()">Add</button>
</div>
